@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* p {
  font-size: 12px;
  font-weight: 300;
} */
.leaflet-popup-content{
  margin: 0px !important;
}

.leaflet-popup-content-wrapper{
  padding: 0px !important;
}

select {
  -webkit-appearance: none;
}

@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

/**Navigation Bar**/
.navigation-bar-default {
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
  margin: 32px 110px 32px 110px;
}

.navigation-bar-left {
  text-align: left;
}

.navigation-bar-right {
  text-align: right;
  padding-top: 40px;
}

.navigation-bar-left {
  display: inline-block;
}
.navigation-bar-right ul {
  display: flex;
  align-items: center;
}

.navigation-bar-right ul li {
  list-style-type: none;
  display: inline-block;
  padding-left: 32px;
}

/**Navigation Bar*/

.right-align-nav-bar {
  text-align: right;
}

.nav-bar-link a:link {
  text-decoration: none;
  color: #5a5a5a;
}

.nav-bar-link a:visited {
  text-decoration: none;
  color: #5a5a5a;
}

.nav-bar-link a:hover {
  text-decoration: none;
  color: #5a5a5a;
}

.nav-bar-link a:active {
  text-decoration: none;
  color: #5a5a5a;
}

.dark-button {
  background-color: #ba2f29;
  color: white;
  border-style: none;
  border-radius: 2px;
  padding: 8px 16px 8px 16px;
}

.dark-button a:link {
  text-decoration: none;
  color: #ffffff;
}

.dark-button a:visited {
  text-decoration: none;
  color: #ffffff;
}

.dark-button a:hover {
  text-decoration: none;
  color: #ffffff;
}

.dark-button a:active {
  text-decoration: none;
  color: #ffffff;
}

.dark-button-large {
  background-color: #ba2f29;
  color: white;
  border-style: none;
  border-radius: 2px;
  padding: 8px 16px 8px 16px;
  font-size: 32px;
  cursor: pointer;
}

.dark-button-large a:link {
  text-decoration: none;
  color: #ffffff;
}

.dark-button-large a:visited {
  text-decoration: none;
  color: #ffffff;
}

.dark-button-large a:hover {
  text-decoration: none;
  color: #ffffff;
}

.dark-button a:active {
  text-decoration: none;
  color: #ffffff;
}

.footer {
  background-color: #34393e;
  color: white;
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 32px 130px 32px 130px;
}

.footer-align-left {
  text-align: left;
}

.footer-header {
  font-weight: bold;
  text-align: left;
}

.footer-nav-list {
  padding-top: 32px;
  text-align: left;
}

.footer-nav-list li {
  padding-bottom: 8px;
  list-style-type: none;
}

.footer-nav-list a:link {
  text-decoration: none;
  color: #ffffff;
}

.footer-nav-list a:visited {
  text-decoration: none;
  color: #ffffff;
}

.footer-nav-list a:hover {
  text-decoration: none;
  color: #ffffff;
}

.footer-nav-list a:active {
  text-decoration: none;
  color: #ffffff;
}

.mapping-ini {
  padding: 64px 130px 64px 130px;
}

.img-and-info {
  display: grid;
  grid-template-columns: auto auto;
}

.uwi-campuses-profiles {
  display: grid;
  grid-template-columns: auto auto auto;
}

.campus-profile {
  max-width: 200px;
  padding: 8px 8px 8px 8px;
}

.map-ini-info h1 {
  color: #ba2f29;
  padding-bottom: 32px;
}

.map-ini-info p {
  padding-bottom: 32px;
}

.map-ini-info {
  padding-right: 56px;
}

.img-init {
  margin-left: 16px;
  max-width: 35px;
}
.faq {
  padding: 64px 130px 64px 130px;
}

.faqs .faq {
  margin-top: 8px;
  margin-left: 0px;
  margin-right: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  border: 1px;
}

.faq h1 {
  padding-bottom: 16px;
}
.faq p {
  padding-bottom: 40px;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 12px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 16px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.faq-answer {
  color: #515151;
}

.faq-question {
  font-weight: 600;
}

/* .faq {
  background: #f6f6f6;
} */
.faq-bdy {
  background: white;
}

/****Everything below older*/

.funder-website-rightBar {
  text-decoration: none;
  color: inherit;
}
.sign-in-btn-admin {
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  padding-bottom: 8px;
  padding-top: 8px;
  color: white;
  border: none;
  font-size: 12px;
  display: flex;
  align-items: center;
}

#fname,
#lname,
#emailsign,
#prevemailsign,
#campuses,
#stype {
  padding-left: 8px;
  padding-right: 8px;
}
#about-project a {
  color: #5a5a5a;
  text-decoration: none;
}
#visit-us a {
  color: #5a5a5a;
  text-decoration: none;
}

.pop-up {
  padding-top: 32px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
}
.pop-up-btn {
  cursor: pointer;
}
.leaflet-container {
  width: 100%;
  height: 600px !important;
  z-index: 0;
}
.map {
  background: rgba(0, 0, 0, 0.8);
}

.box {
  border: 1px solid black;
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.box h1 {
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: bold;
}
.image-container {
  display: flex;
  margin-bottom: 32px;
}
.img-1 {
  margin-right: 16px;
}
.img-1,
.img-2 {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 32px;
  border-radius: 10px;
}
.img-1 img,
.img-2 img {
  margin-bottom: 16px;
}
.img-1 p,
.img-2 p {
  margin-bottom: 16px;
}
.img-1 span,
.img-2 span {
  color: #495057;
}
.img-1 p,
.img-2 p {
  font-size: 12px;
  font-weight: bold;
}
.box button {
  background-color: #ba2f29;
  color: white;
  border: none;
  font-size: 24px;
  padding: 8px 8px 8px 8px;
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }

  .nav__item--extra {
    flex-grow: 2;
  }

  .footer__addr {
    flex: 1 0px;
  }

  .footer__nav {
    flex: 2 0px;
  }
}

/**margin contents**/
.mapping-area,
.our-sponsors-content {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-left: 230px;
  margin-right: 230px;
}
/**Nav Bar**/

.top-nav-bar {
  border-bottom: solid 1px rgb(201, 201, 201);
}

.bottom-nav-bar {
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.bottom-nav-bar > h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}

/**Buttons**/
.light-button {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgb(199, 199, 199);
  color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  font-weight: bold;
}

/**Map**/

/**Our Sponsors**/
.our-sponsors {
  background-color: rgba(241, 243, 245, 1);
}

.our-sponsors-content {
  text-align: center;
}

.our-sponsors-images-carousel {
  padding-top: 32px;
}

.our-sponsors-images-carousel > img {
  padding-left: 84px;
  padding-right: 84px;
}
.collapsible {
  outline: none;
  border: none;
  float: right;
  background-color: white;
  font-size: 12px;
  cursor: pointer;
  background-color: #f6f6f6;
}

.collapsible:after {
  content: "\002B";

  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  /* content: "\2212"; */
}

.content {
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  width: 100%;
  padding-top: 10px;
  padding-left: 0px;
}

.map_sec {
  background-color: white;
  width: 100%;
  height: 600px;
}

/**change password**/
.passBody {
  padding-left: 230px;
  padding-right: 230px;
  padding-bottom: 64px;
}
.changepwd {
  padding-top: 64px;
}
.change {
  background-color: #871e1a;
  border-radius: 2px;
  border-style: none;
  color: white;
  width: 166px;
  height: 36px;
  font-weight: bold;
  font-size: 12px;
}
.change_pass {
  padding-top: 16px;
}
.change_cancel {
  padding-top: 32px;
}

.input-p {
  /* width: 444px; */
  height: 40px;
  border: 1px solid rgb(198, 198, 198);
  border-radius: 2px;
}
.cancel {
  font-size: 12px;
  width: 52px;
  height: 20px;
  padding-left: 10px;
  cursor: pointer;
}

.input-c {
  /* width: 444px; */
  height: 40px;
}


/* #csvFileInput{
      display:none;
    } */
.clicktobrowse {
  cursor: pointer;
}
tr {
  /* box-shadow: 0px 0px 0px 1px rgb(14, 75, 14); */
  border-radius: 5px;
  /* padding-right: 1000px; */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
    .img-and-info{
    display: flex;
    
    }
    .mapping-ini{
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin-left: 230px;
    margin-right:230px;
    
    }
    .Gallery{
    cursor: pointer;
    
    }
    .row-1-images{
    width: 100%;
    display: flex;
    justify-content: space-between;
    }
    .row-2-images{
    display: flex;
    justify-content: space-between;
    width: 100%;
   } /*  */
/* .map-ini-info p{
    font-size: 12px;
    margin-bottom: 32px;
    white-space: nowrap;
    }
    .map-ini-info{
    margin-right: 32px;
    }
    .map-ini-info h1{
    font-size: 48px;
    font-weight: bold;
    color: #BA2F29;
    margin-bottom: 32px;
    }
    .map-ini-info button{
    font-size: 32px;
    background-color: #871E1A;
    color: white;
    padding: 8px 16px 8px 16px;
    border: none;
    }  */
     
    .side-buttons{
    height: 600px;
    
    display: flex;
    flex-direction:column;
    background-color: #BA2F29;
    }
    #other-filter{
    border: 2px solid black;
    }
    .side-buttons li{
    height: 100px;
    width: 100px;
    border-bottom: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    background-color: #BA2F29;
    color: white;
    flex-direction: column;
    font-size: 14px; 
    } 
    .side-buttons li img{
    width: 30px;
    margin-bottom: 10px;
    }
    
    .pop-out-bar{
    display: flex;
    z-index: 10;
    position: relative;
    height: auto;
    width: auto;
    position: absolute;
    top: 0;
    width: 100px;
    height: 500px;
    }
    
    .side-bar-overlay{
    position: relative;
    } 
    .overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.48);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .map-bar{
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    } 
    .map-bar{
    position: relative;
    
    }

    tr {
    /* box-shadow: 0px 0px 0px 1px rgb(87, 16, 16); */
    border-radius: 5px;
    /* padding-right: 1000px; */
    
    
    
    }
 
    
    *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    .table-mng{
    padding-right: 230px;
    padding-left: 230px;
    margin-top: 100px;
    
    border-collapse: separate;
    border-spacing: 0px 12px;
    padding-bottom: 40px;
    /* border-radius: 5px; */
    
    }

    
.sec1 .test1 {
  background-color: #ffffff;
  padding-top: 20px;
}

.sec2 .test1 {
  padding-top: 20px;
}

.sec1 .test2 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sec1 .test3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sec2 .test3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sec2 .test2 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sec2 {
  background-color: #f6f6f6;
}

.topnav h1 {
  display: inline-block;
  float: left;

  padding-left: 115px;
  padding-top: 64px;
}
.topnav form {
  position: absolute;
  right: 8%;
  margin-top: 29px;
}
.search-container input {
  /* width: 339px; */
  height: 35px;
  padding: 8px;
  border: 1px solid rgb(198, 198, 198);
}
.search-btn-grid {
  box-sizing: border-box;
  width: 84px;
  height: 35px;
  margin-left: 16px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  color: #3167a7;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}
.search-container-manage input {
  /* width: 339px; */
  height: 35px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  margin-top: 35px;
}
.search-container {
  padding-top: 32px;
}
.search-container-manage {
  padding-top: 32px;
}
.search-container ::placeholder {
  background-image: url("../src/Images/Group\ 36.png");
  background-repeat: no-repeat;
  height: 19px;
  background-position: right;
  padding-left: 10px;
  vertical-align: middle;
}
.search-container-manage ::placeholder {
  background-image: url("../src/Images/Group\ 36.png");
  background-repeat: no-repeat;
  height: 19px;
  background-position: right;
  padding-left: 10px;
  vertical-align: middle;
}
.topnav {
  padding-bottom: 3px;
}
.v303_9728 {
  width: 100%;
  height: 84px;
  background: rgba(149, 10, 17, 1);
  opacity: 1;

  top: 271px;
  left: 0px;
  overflow: hidden;
}
.v303_9780 {
  width: 394px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  top: 303px;
  left: 228px;
  overflow: hidden;
}
.v303_9782 {
  width: 139px;
  color: red;
  top: 0px;
  left: 0px;

  font-weight: SemiBold;
  font-size: 12px;
  opacity: 1;
  text-align: left;
}
.name {
  color: #fff;
}

.name {
  color: #fff;
}
.usr {
  top: 225px;
  left: 100px;
  position: absolute;
  color: white;
}
.usr-int {
  top: 225px;
  left: 300px;
  position: absolute;
  color: white;
}

.up-init {
  text-align: center;
}
.p-init {
  padding-bottom: 56px;
  padding-top: 16px;
}
.init {
  padding-top: 64px;
}
.drag {
  text-align: center;
}
.up-drag-img {
  width: 412px;
  height: 311px;
  padding-bottom: 64px;
  border: 2px dashed #c0c0c0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 64px;
}
.up-drag-img p {
  text-align: center;
}
.up-drag-img img {
  width: 40%;
  height: 55%;
  display: block;
  margin-top: 15%;
  margin-left: 5%;
}

/* Add User Page */

.change {
  background-color: #871e1a;
  border-radius: 2px;
  border-style: none;
  color: white;
  width: 166px;
  height: 36px;
  font-weight: bold;
  font-size: 12px;
}

.cancel {
  border: none;
  background-color: white;
}

.fl-input input {
  /* width: 218px; */
  height: 40px;
  margin-bottom: 8px;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
}
.fl-inputs input {
  /* padding-top: 10px; */
  padding-left: 10px;
  /* width: 464px; */
  border-radius: 5px;
  height: 40px;
  margin-bottom: 8px;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
}
.fl-inputs1 input {
  padding-top: 10px;
  padding-left: 10px;
  /* width: 464px; */
  border-radius: 5px;
  height: 0px;
  margin-bottom: 8px;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
}


.usrBody {
  margin-left: 230px;
  margin-right: 230px;
  padding-bottom: 64px;
  padding-top: 64px;
}
.lname {
  padding-left: 150px;
}

.a-name,
.email,
.schools,
.utypes {
  padding-bottom: 10px;
}
.email,
.schools,
.utypes {
  padding-top: 10px;
}
.e-input input {
  /* width: 445px; */
  height: 40px;
  margin-bottom: 8px;
  border: 1px solid #d1d1d1;
}
.s-campus select,
.s-type select {
  width: 445px;
  /* padding: 50px; */
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #d1d1d1;
  color: grey;
}

.s-type select {
  margin-bottom: 20px;
}
.change-u {
  background-color: #871e1a;
  border-radius: 2px;
  border-style: none;
  color: white;
  width: 89px;
  height: 36px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.usrH1 {
  padding-bottom: 15px;
}
.add_usr {
  padding-bottom: 15px;
  color: #6b6b6b;
  margin-bottom: 16px;
}
.side-buttons li {
  height: 100px;
  width: 100px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  background-color: #ba2f29;
  color: white;
  flex-direction: column;
}
.side-buttons li img {
  margin-bottom: 10px;
}

/* .side-bar{
    display: flex;
    align-items: center;
    z-index: 10;
    background-color: rgba(0,0,0,0.48);
    position: relative;
    height: auto;
    width: auto;
    }
    
    .side-bar{
    width: 124px;
    height: 500px;
    
    }
    .side-bar-overlay{
    position: relative;
    } 
    .overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.48); */

/* } */

.hidden {
  display: none;
}
.none {
  overflow-y: hidden;
}
.active {
  height: 30px;
}

.act_img {
  transform: rotate(45deg);
}
.center-upload {
  text-align: center;
}
.center-upload h1 {
  font-size: 32px;
  font-weight: bold;
}
.center-upload p {
  color: #6b6b6b;
  font-size: 12px;
  margin-top: 16px;
}
.center-upload button {
  border: none;
  color: #2068bd;
  margin-top: 64px;
}
.upload-container {
  padding-top: 115px;
  padding-bottom: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-bar {
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
}
.top-bar-container ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  position: relative;
}
.top-bar-container button {
  border: none;
  color: #5ca766;
  font-size: 12px;
  margin-right: 15%;
  background: none;
}
.top-bar-container ul li {
  color: #5ca766;
  font-size: 12px;
}
.top-bar-container ul li button {
  border: 1px solid #5ca766;
  font-size: 12px;
  font-weight: 700;
  border-radius: 57px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
}
.top-bar-container {
  display: flex;

  justify-content: center;
}
.sec-bar-2 {
  /* background-color: #950a11; */
  /* padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 120px;
  z-index: 99; */
}
.sec-bar-dropdowns {
  background: none;
  display: flex;
  border: none;
  outline: none;
  /* border: 1px solid black; */
  margin-left: 100px;
}
.sec-bar-dropdowns option {
  background: none;
  outline: none;
}
.sec-bar-dropdowns button {
  background: none;
  outline: none;
  color: white;
  margin-right: 50px;
  font-size: 12px;
  font-weight: 700;
  background-color: none;
  border: none;
}

.sec-bar-2 {
  /* display: flex; */
}
/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px 16px 16px 0px;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  cursor: pointer;
}

.dropdown-content-posted {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left:205px;
  margin-top:45px;
}
.dropdown-content-Admin {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  border: 1px solid #f4f4f4;
  z-index: 1;
  top: 82px;
  color: red;
}
.admin-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Links inside the dropdown */
.dropdown-content-Admin a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown-content-Admin a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}
.showAdmin {
  display: block;
  margin-left: 12px;
}
.img_sec {
  padding-left: 5px;
  padding-bottom: 2px;
}
.sign-in-btn {
  cursor: pointer;
  border-radius: 2px;
}
#sign-in {
  background-color: #ffffff;
  color: #5a5a5a;
  border: 0.5px solid #767676;
}
.img_sel {
  padding-left: 5px;
  padding-bottom: 3px;
}
.sel-camp1,
.sel-alu,
.sel-uwi,
.sel-staff,
.sel-stu {
  cursor: pointer;
}

.table1 {
  margin-top: 32px;
  margin-left: 230px;
  margin-bottom: 30px;
}
.table-head1 {
  display: flex;
  /* border: 1px solid black; */
  /* border-radius: 5px; */
  width: 1100px;
  margin-bottom: 8px;
  white-space: nowrap;
  justify-content: space-around;
  background-color: #f6f6f6;
}
.table-head1 div {
  width: 150px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 40px;
}
.row1 {
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
  width: 1100px;
  margin-bottom: 8px;
  justify-content: space-around;
  background-color: #ffffff;
}
.row1 div {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 40px;
  white-space: nowrap;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apph1 {
  padding-top: 64px;
  margin-left: 120px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.SDGs-section-home-page {
  padding: 64px 130px 64px 130px;
  background: #f6f6f6;
}

.SDGs-section-header {
  text-align: center;
  padding-bottom: 32px;
}

.SDGs-section-header h1 {
  color: #ba2f29;
}

.SDGs-section-content {
  /* display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  justify-items: center; */
  text-align: center;

  /* align-items: center; */
}

.SDGs-section-content img {
  padding: 8px 8px 8px 8px;
  height: 130px;
}

.sec-1 {
  margin-left: 230px;
  margin-right: 230px;
}

.posted-initiatives-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
  padding-bottom: 64px;
}
.posted-initiatives-table {
  padding-left: 230px;
  padding-right: 230px;
  padding-top: 32px;
}

.posted-initiatives-table tr {
  border: 10px solid red;
}
.zero-ref {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.frst-ref {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.scnd-init {
  border-left: none;
  border-right: none;
  pointer-events: none;
}
.thrd-stat {
  border-left: none;
  border-right: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* .frth-stat{
      border-left:none;
     border-right:none;
     border-top-right-radius: 5px;
     border-bottom-right-radius: 5px;
    } */
.projectImageUrl {
  height: 100px;
}
.frth-opt {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


tr > th {
  background-color: #f6f6f6;
  /* font-size: 12px;
  font-weight: 700; */
}

.status-ongoing {
  background-color: #e4bc55;
}
.ONGOING {
  background-color: #e4bc55;
}
#ONGOING {
  background-color: #e4bc55;
}
.status-complete {
  background-color: #6bd35a;
}

table button {
  color: white;
  border-style: none;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 2px;
}
/* .float-right-testing {
      float: right;
      margin-left: 20px;
    }
    .left-container {
      max-width: 800px; /* or any other value you prefer */
/* margin: 0 auto; to center the container on the page */

.left-container {
  max-width: 800px;
  padding-top: 8px;
  padding-left: 120px;
  /* margin: 0 auto; */
  width: 800px;
}

.div-flex {
  display: flex;
  width: 50%;
}
.float-right-testing {
  float: right;
  /* margin-left: 20px; */
}

.projectImageUrls2 {
  /* position: absolute; */
  padding-bottom: 32px;
  /* border-radius: 5px; */
  width: 595px;
  height: 400px;
  padding-right: 0;
  margin-right: 22px;
  margin-top: 10px;
  object-fit: cover;
}

.full-lead {
  border: 1px solid #a0a0a0;
  margin-left: 57%;
  margin-top: 20px;
  /* padding-left:98px; */
  /* padding-top:32px; */
  border-radius: 5px;
  width: 595px;
  color: #5a5a5a;
  /* padding-bottom: 10px; */
}
.filter-wrapper {
  width: 352px;
  padding: 1.25rem 1rem 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.filter-width {
  width: 307px;
}
.filter-apply {
  cursor: pointer;
}
.filter-title {
  color: #4a5568;
  font-weight: 600;
  line-height: 1.5;
}

.filter-section {
  margin-top: 1.75rem;
  color: #4a5568;
  font-weight: 600;
  line-height: 1.5;
}

.filter-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.375rem;
  padding: 0.625rem 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.125rem;
  background-color: #ffffff;
  color: #4a5568;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
}
.filter-dropdown1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.375rem;
  padding: 0.625rem 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.125rem;
  background-color: #ffffff;
  color: #4a5568;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
}
.dropdown-width {
  width: 236px;
}
.init-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.375rem;
  padding: 0.625rem 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.125rem;
  background-color: #ffffff;
  color: #a3a3a3;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
  width: 307px;
}
.init-Country-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.375rem;
  padding: 0.625rem 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 0.125rem;
  background-color: #ffffff;
  color: #a3a3a3;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
  width: 307px;
}

.filter-checkbox {
  margin-top: 0.875rem;
}
.search-containers ::placeholder {
  color: #a3a3a3;
  font-weight: 500;
}
.filter-sdgs {
  font-weight: 500;
  color: #000000;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  gap: 30px;
  /* margin: 0 auto; */
  margin-left: 110px;
  max-width: 1220px;
  padding-left: 0;
  padding-right: 0;
}

.filter-box {
  grid-column: 1 / 2;
}

.grid-container {
  grid-column: 2 / 3;
}
.row-upload-download-del-div {
  width: max-content;
  display: inline-flex;
  align-items: center;
}
.filter-checkbox-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.filter-checkbox-box {
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  border: 2px solid #cbd5e0;
  border-radius: 0.125rem;
}

.checkbox-size {
  width: 1rem;
  height: 1rem;
}

.filter-checkbox-label {
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
}

.checkbox-label-size {
  width: 10rem;
}

.filter-buttons {
  margin-top: 1.5rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.apply-width {
  width: 176px;
  padding: 0.625rem 1rem;
  border: 1px solid #1a202c;
  border-radius: 0.375rem;
  background-color: #ffffff;
  color: #3167a7;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
  padding-left: 60px;
}
.filter-init-btn {
  color: #3167a7;
  text-decoration: none;
  font-weight: 600;
  /* padding-right: 4px; */
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.filter-init-clear {
  color: #e53e3e;
  text-decoration: none;
  font-weight: 600;
  /* padding-right: 4px; */
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.clear-width {
  width: 130px;
  margin-left: 0.875rem;
  padding: 0.625rem 1rem;
  /* border: 1px solid #E53E3E; */
  border-radius: 0.375rem;
  background-color: #ffffff;
  color: #e53e3e;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.025em;
  padding-left: 10px;
}

.full-table {
  padding-bottom: 64px;
  padding-top: 16px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
  padding-left: 115px;
  padding-right: 115px;
}
.full-table th {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.full-button {
  margin-left: 50%;
  padding-left: 98px;
  padding-bottom: 16px;
  padding-top: 16px;
}
.full-button a {
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
}
.full-init {
  margin-left: 70%;
  /* padding-left:98px; */
  padding-bottom: 16px;
  color: #871e1a;
  font-size: 24px;
  cursor: pointer;
  /* font-weight: 600; */
  /* padding-top:16px; */
}
.full-view-more {
  font-weight: 600;
}
.full-button button {
  background-color: #871e1a;
  color: white;
  text-decoration: none;
  border: none;
  width: 595px;
  height: 50px;
  border-radius: 2px;
  font-size: 32px;
}
.full-button a {
  color: white;
  text-decoration: none;
}
.contact {
  padding-bottom: 16px;
  /* padding-top:16px; */
  padding-left: 16px;
}
.lead {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
}
.members {
  padding-bottom: 16px;
  /* padding-top:16px; */
  padding-left: 16px;
}
.search-containers input {
  /* width: 352px; */
  height: 35px;
  padding: 8px;
  border: 1px solid rgb(198, 198, 198);
  border-radius: 2px;
  margin-left: 110px;
}
.search-containers {
  padding-bottom: 10px;
}
.search-containers ::placeholder {
  background-image: url("../src/Images/Group\ 36.png");
  background-repeat: no-repeat;
  height: 19px;
  background-position: right;
  padding-left: 10px;
  vertical-align: middle;
}
.left-container p {
  float: left;
  clear: both; /* add clear property to clear the float after the text */
}

.init-num {
  color: #3167a7;
  text-decoration: none;
  font-weight: 600;
}
.markerImg {
  /* height: 200px;
  width: 100%;
  padding-right: 0;
  object-fit: cover; */

  /* overflow-y:hidden; */
  /* margin-top: 10px; */
}
.markerImg2 {
  /* height: 200px;
  width: 100%;
  padding-right: 0;
  object-fit: cover; */

  /* overflow-y:hidden; */
  /* margin-top: 10px; */
}
.markerImg3 {
  /* height: 160px;
  width: 100%;
  padding-right: 0;
  object-fit: cover; */
}
.flexing {
  display: flex;
  justify-content: center;
}
.download-csv {
  color: #3167a7;
  text-decoration: none;
  font-weight: 600;
  padding-right: 4px;
  background-color: transparent;
  cursor: pointer;
}
.download-csv1 {
  color: #3167a7;
  text-decoration: none;
  font-weight: 600;
  /* padding-right: 4px; */
  background-color: transparent;
  cursor: pointer;
}
.full-funders {
  padding-left: 120px;
  padding-top: 8px;
  color: #5a5a5a;
}
.full-title {
  padding-left: 120px;
  padding-top: 16px;
}
.full-res {
  padding-left: 120px;
  padding-top: 16px;
}
.full-res1 {
  padding-left: 120px;
  padding-top: 16px;
  padding-right: 115px;
}
.full-sdg {
  padding-top: 8px;
  padding-left: 120px;
  color: #5a5a5a;
  width: 50%;
}
.full-theme {
  padding-top: 8px;
  padding-left: 120px;
  color: #5a5a5a;
  width: 50%;
}
.delete-csv {
  color: #c1413c;
  text-decoration: none;
  font-weight: 600;
  padding-left: 4px;
  background: #ffffff;
  cursor: pointer;
  padding-right: 14px;
}
.add-csv {
  color: green;
  text-decoration: none;
  font-weight: 600;
  padding-left: 4px;
  background: #ffffff;
  cursor: pointer;
  padding-right: 14px;
}

.drpdown1 {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: white;
  top: 6%;
  left: 19%;
  width: 150px;
  text-align: center;
  padding-top: 10px;
}
.drpdown1 a {
  margin-bottom: 10px;
}
.sel-camp1 {
  white-space: nowrap;
}
.Login-top {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  text-align: center;
  background-color: #950a11;
  color: white;
}
.login-center {
  width: 100%;
  /* display: flex; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.forgot-center {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.forgot-form-btn {
  cursor: pointer;
}
.login-center .login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
}
.login-center .login_form {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 64px;
}
.login-center .login_form input {
  width: 290px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  border: 1px solid #c0c0c0;
}
.login-center .login_form h1 {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
.login-center .login_form button {
  width: 290px;
  height: 40px;
  margin-bottom: 14px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}
.log-in-btn {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  background-color: white;
}
.forgot-center .forgot_form {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
  background-color: #ffffff;
  /* border-radius: 5px; */
}
.forgot-center .forgot_form {
  padding-left: 32px;
  padding-right: 32px;
  /* margin-top: 64px;
  margin-bottom: 150px; */
}

.forgot-center .forgot_form input {
  width: 290px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  border: 1px solid #c0c0c0;
}
.forgot-center .forgot_form h1 {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
.forgot-center .forgot_form button {
  width: 290px;
  height: 40px;
  /* margin-bottom: 32px; */
  font-size: 12px;
  font-weight: 700;
  margin-top: 20px;
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}
.check .chk-box {
  margin-top: 5px;
}

.txtbox {
  float: left;
}
.email-div {
  margin-top: 30px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.pword-div {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
/* .pword-div label {
  margin-bottom: 4px;
}
.email-div label {
  margin-bottom: 4px;
} */
.checkbox-login {
  width: 20px;
  height: 20px;
  border: 1px solid black;
}
#checkbox-testing {
  width: 20px;
  height: 20px;
}
.remember-me-div {
  display: flex;
  width: 290px;
  height: auto;
  margin-bottom: 8px;
}
/* .remember-me-div label {
  margin-left: 11px;
} */
.forgot-btn {
  margin-bottom: 32px !important;
  height: 10px;
  background: none;
  height: auto !important;
  width: auto !important;
  border: none;
  align-self: flex-end;
  font-weight: normal !important;
  cursor: pointer;
}
.forgot-btn a:visited {
  color: black;
}
.forgot-btn a:link {
  text-decoration: none;
}
.btnRows {
  display: flex;
}

.dont-have-acc {
  margin-bottom: 114px;
  font-size: 12px;
}
.dont-have-acc button {
  border: none;
  background: none;
  font-weight: bold;
  font-size: 12px;
  color: black;
  text-decoration: none;
}
.go-back-home {
  cursor: pointer;
}

.leaflet-popup-content-wrapper {
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
    height: 350px; /* Adjust to your desired height */

    /* Enable vertical scrolling when content overflows */
    /* overflow-y: auto; */
  width: 360px;
  border-radius: 8px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 1);
  padding: 0;
}
.downloadCSV {
  cursor: pointer;
}
.popupDesc {
  max-width: 144ch;
  overflow-y: scroll;
  text-overflow: ellipsis;
  padding-right: 16px;
  padding-left: 16px;
  overflow: hidden;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
      }
      .popupDescs{
        max-width: 144ch; /*limit with spaces*/
      overflow-y: scroll;
      text-overflow: ellipsis;
  margin-bottom:16px;
      overflow:hidden;
      white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: 10px;
  text-align: left;
  padding-top:16px;
      }
    .sdg{
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 16px;
    padding-left: 16px;
    }
    .sdgs{
      /* padding-bottom: 4px; */
      padding-top: 16px;
      text-align: left;
      padding-left:15px;
      }
    .since{
      padding-left: 16px;
    padding-left: 16px;
    }
    .question-section {
    background: transparent;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    width: 92vw;
    }
    
    .question-align {
    align-items: center;
    display: flex;
    min-height: 10px;
    text-align: left;
    }
    
    .question-align h4 {
    margin-left: 8px;
    }
    
    .question-styling {
    font-size: 17px;
    width: 100%;
    }
    
    .question-icon {
    background: none;
    border: none;
    color: #1b6aae;
    cursor: pointer;
    font-size: 22px;
    margin-left: auto;
    margin-right: 8px;
    }
    
    .rotate {
    transform: rotate(45deg);
    }
    
    .answer-divider {
    border-top: 1px solid lightgray;
    }
    
    .answer {
    margin-left: 8px;
    margin-right: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
    }
    
    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    
    header h1 {
    color: #eee;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    }
    
    .manage-usr {
      padding-bottom: 32px;
      padding-top: 32px;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 15px;
      padding-left: 16px; /* Adjust this value as needed */
      padding-right: 16px; /* Adjust this value as needed */
    }
    
    .inventory-upload{
      padding-bottom:32px;
    padding-top:32px;
    width:100%;
    border-collapse: separate;
    border-spacing: 0px 15px;
    padding-left: 115px;
    padding-right: 115px;
    }
    .mng{
    text-decoration: none;
    color: inherit;
    cursor: auto;
    pointer-events: none;
    }
    
    .filter{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 6px;
    border-radius: 2px;
    border: 1px solid #A3A3A3;
    background-color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    }
    .filter img{
    padding-left: 10px;
    }
    .usr_icon{

      height:20%;
    
    }
    .center {
    text-align: center;
    padding-bottom:64px;
    }
    
    /* .pagination {
    display: inline-block;
    }
    
    .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    
    margin: 0 4px;
    }
    
    .pagination a.active-page {
    background-color: #4CAF50;
    color: white;
    
    
    }
    
    .pagination a:hover:not(.active-page) {background-color: #ddd;} */
    /* Pagination */
    .pagination-container {
    list-style-type: none;
    }
    
    
    .pagination-item {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 4px;
    }
    
    
    .pagination-item.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      .pagination-item:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
    
      .pagination-item.selected {
        background-color: #4CAF50;
      }
    
      
        .arrow::before {
          position: relative;
         
          content: '';
     
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
    
         .left {
          transform: rotate(-135deg) translate(-50%);
        }
    
         .right {
          transform: rotate(45deg);
        }
      
    
       .disabled {
        pointer-events: none;
      }
    
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
    
        .arrow :hover {
          background-color: transparent;
          cursor: default;
        }
      
    
    
    .test{
    float:right;
    margin-right:  780px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    }
    .init-status{
    color: #5A5A5A;
    font-size: 16px;
    font-weight: 400;
    padding-left: 18px;
    padding-top: 10px;
    }
    
    .search-container .ongoing{
    width: 50px;
      height: 25px;
      margin-top: 10px;
      margin-left: 5px;
      display:block;
    }
    .search-container .name-tag{
    float: right;
    padding-top: 15px;
    padding-right: 50px;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    display:block;
    margin-top: -38px;
    }
    .search-container .test-tag{
    float: right;
    padding-top: 15px;
    padding-right: 67px;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    margin-top:-35px
    
    }
    .search-container .final-tag{
    float: right;
    padding-top: 15px;
    padding-right:55px;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    margin-top:-45px;
    
    }
    .search-container .paused{
    width: 50px;
      height: 25px;
      margin-top: 10px;
      margin-left: 5px;
      display:block;
    }
    .search-container .completed{
    width: 50px;
      height: 25px;
      margin-top: 10px;
      margin-left: 5px;
      display:block;
      margin-bottom: 10px;
    }
    /* .posted-hr{
    padding-bottom: 10px;
    } */
    
    .postedapply{
    font-weight: 500;
    font-size: 16px;
    color: #2A68C5;
    float:right;
    margin-right: 30px;
    cursor: pointer;
    }
    .postedcancel{
    float:left;
    font-weight: 400;
    font-size: 16px;
    color: #989898;
    margin-left: 30px;
    cursor: pointer;
    }
    .searchtext{
    padding-top:10px;
    padding-bottom: 30px;
    }
    .login-home{
    text-decoration: none;
    color:black;
    }
    
    .pagIstyles {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }
    
    .Active{
    background-color: #4CAF50;
    padding-left: 16px;
    padding-right: 16px;
    }
    
    .Ongoing{
    background-color: #E4BC55;
    padding-left: 16px;
     padding-right: 16px;
     text-transform: uppercase;
    }
      .ongoing{
      background-color: #E4BC55;
      padding-left: 16px;
     padding-right: 16px;
     text-transform: uppercase;
    }
    #ongoing{
      background-color: #E4BC55;
      padding-left: 16px;
     padding-right: 16px;
     text-transform: uppercase;
    }
    .ONGOING{
      background-color: #E4BC55;
    padding-left: 8px;
     padding-right: 8px;
    }
    
    .topPopup{
      text-align: center;
      font-size: 16px;
      color:white;
      width:auto;
      /* margin-left: 32px; */
      /* margin-top: -40px; */
      height:50px;
      font-weight: 800;
      border-radius: 5px 5px 0px 0px;
      padding-top:15px;
      /* position: absolute; */
    }
    .topPopUp{
      text-align: center;
      font-size: 16px;
      color:white;
      width:auto;
      /* margin-left: 32px; */
      /* margin-top: -40px; */
      height:50px;
      font-weight: 800;
      /* border-radius: 5px 5px 0px 0px; */
      padding-top:15px;
      /* position: absolute; */
    }
    #ONGOING{
      background-color: #E4BC55;
      /* position: -webkit-sticky;
      position: sticky;
      top: 0; */
      
    }
    /* #COMPLETE{
      background-color: #4CAF50;
      /* position: -webkit-sticky;
      position: sticky;
      top: 0; 
    } */
    /* #Complete{
      background-color: #4CAF50;
      text-transform: uppercase;
    } */
    
    #Ongoing{
      background-color: #E4BC55;
      text-transform: uppercase;
    }
    .INACTIVE{
    background-color: #E4BC55;
    padding-left: 16px;
     padding-right: 16px;
    }
    .COMPLETE{
    background-color: #4CAF50;
    padding-left: 16px;
     padding-right: 16px;
    }
    .Complete{
      background-color: #4CAF50;
      padding-left: 16px;
       padding-right: 16px;
       text-transform: uppercase;
      }
      .complete{
    background-color: #4CAF50;
    padding-left: 16px;
     padding-right: 16px;
     text-transform: uppercase;
    }
    .ACTIVE{
      background-color: #4CAF50;
      padding-left: 16px;
       padding-right: 16px;
      }
    
    .table-test{
    display:none;
    }
    .lock{
    cursor: pointer;
    background-color:transparent;
    }
    .row-img{
      width:auto;
      height:25px;
    }
    .row-img1{
      width:auto;
      height:10px;
      padding-left:10px;
    }
    .return{
      background-color: transparent;
      cursor:pointer;
    }
    .rightBarBtn a:visited{
      color:white;
    }
    .rightBarBtn a{
      text-decoration: none;
      color: white;
    }
    .lock1{
      cursor: pointer;
    background-color:transparent;
    }
    .lock2{
      height:20px;
      width: 20px;
      cursor: pointer;
    background-color:transparent;
    }
    .lock3{
      height:20px;
      width: 20px;
      cursor: pointer;
    background-color:transparent;
    margin-left:20px;
    }
    .lock-open{
    cursor: pointer;
    background-color:#ffffff;
    }
    .filter-bar{
    padding:32px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 388px;
    color: white;
    background-color: #BA2F29;
    }
    .filter-bar p{
    font-size: 12px;
    margin-bottom: 32px;
    
    }
    .filter-bar h1{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 18px;
    }
    .filter-dropdown1{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    }
    .filter-dropdown1 label{
    font-size: 14px;
    }
    .filter-dropdown1 select{
    margin-top: 8px;
    height: 35px;
    width: 324px;
    }
    .Filter-btn{
    height: 35px;
    width: 324px;
    margin-bottom: 32px;
    margin-top: 32px;
    font-size: 16px;
    font-weight: bold;
    background-color: black;
    color: white;
    border: none;
    }
    .Reset-btn{
    height: 35px;
    width: 324px;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: bold;
    color: white;
    }
    
    /*
    .pop-out-img{
    width: 100px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    }
    .pop-out-img img{
    margin-bottom: 16px;
    }
    .pop-out-filter{
    width: 300px;
    height: 100px;
    background-color: #2068BD;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .pop-out-filter select{
    width: 200px;
    height: 30px;
    }
    .pop-out-filter button{
    height: 30px;
    width: 60px;
    margin-left: 16px;
    }
    .search-pop-out{
    display: flex;
    position: absolute;
    }
    .pop-out-icons{
    
    }*/
    .pop-out-bar{
    width: 100px;
    background-color: #BA2F29;
    
    }
    .pop-out{
    width: 100px;
    height: 85px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #BA2F29;
    color: white;
    cursor: pointer;
    }
    .pop-out-filter{
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    position: absolute;
    left: 110%;
    text-align: left;
    padding: 8px 8px 8px 8px;
    background-color: white;
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    
    }
    .pop-out-filter1{
      border: 1px solid #D6D6D6;
      border-radius: 5px;
      position: absolute;
      left: 110%;
      text-align: left;
      padding: 6px 6px 6px 6px;
      background-color: white;
      width: 0;
      overflow: hidden;
      white-space: nowrap;
      margin-top: 70px;
      }
      .pop-out-filter1 select{
        width: 200px;
        height: 30px;
        border: 1px solid #767676;
        outline: none;
        color:#585858;
        }
        .pop-out-filter1 button{
        height: 30px;
        width: 50px;
        cursor:pointer;
        border: none;
        font-size: 16px;
        background-color: #BA2F29;
  color: white;
        }

    .pop-out-filter select{
    width: 200px;
    height: 30px;
    border: 1px solid #767676;
    outline: none;
    color:#585858;
    }
    .pop-out-filter button{
    height: 30px;
    width: 50px;
    background-color: #BA2F29;
  color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    }
    .pop-out-icon{
    width: 100px;
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid white;
    }
    .filter-title{
    width: 100%;
    margin-bottom: 8px;
    font-weight: 600;
    color: #666666;
    }
    .pop-out-filter input{
      width: 200px;
        height: 30px;
        border: 1px solid #767676;
        outline: none;
        color:#585858;
    }
    .pop-out-filter1 input{
      width: 200px;
        height: 30px;
        border: 1px solid #767676;
        outline: none;
        color:#585858;
    }
    .pop-out-filter .searchfil{
      
      width: 60px;
    }
    .pop-out-filter1 .searchfil{
      
      width: 60px;
    }
    .pop-out-filter div{
    width: 100%;
    }
    .dropdown-filter-section{
    display: grid;
    grid-template-columns: auto auto;
    
    }
    .dropdown-filter-section1{
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 20px;
      }
    #pop-out-2{
    display: none;
    }
    #pop-out-1{
    display: none;
    }
    #pop-out-3{
    display: none;
    }
    #pop-out-4{
    display: none;
    }
    #pop-out-5{
    display: none;
    }
    #pop-out-6{
      display:none;
    }
    #pop-last{
    border-bottom: 1px solid transparent;
    
    }
    #other_filter{
      border: none;
      
    }
    .diving {
      display: none;
      z-index: 10;
      position: absolute;
      top: 0;
      width: 360px;
      right: 0;
      flex-direction: column;
      background-color: white;
      border: 1px solid #d0d0d0;
      min-height: 600px;
      padding-bottom: 80px; /* Add padding at the bottom */
    }
    
    .itemTest{
    direction: ltr;
    display: flex;
    }

    .currentPN{
      font-size: 20px;
    }
    .itemTest1{
    direction: ltr;
    text-align: left;
    color: #575757;
    }
    .close-btn{
      position: absolute;
      right: 10px;
      background: none;
      border: none;
      cursor:pointer
    }
    
    .topSec{
    /* background-color: #91BD55; */
    /* margin-bottom: 16px; */
    height: 52px;
    width: 359px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: white;
    }
    .currentP{
      font-size: 20px
    }
    .secSection{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    font-weight: 600;
    font-size: bold;
    padding-left: 16px;
    padding-right: 16px;
    }
    .sdgSection{
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    }
    .emailSection{
      display: flex;
      padding-left: 16px;
      /* padding-right: 16px; */
      margin-bottom: 8px;
      color: #575757
      }
    .citySection{
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;
      }
      .beforeDesc{
        height: 100px;
        overflow-y: scroll
      }
      
    .dateSection{
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 12px;
    }
    
    .descSection{
    color: #575757;
    display: flex;
    margin-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    /* overflow-y:scroll;  */
    height:50px;
    /* direction: rtl;
    text-align: left; */
    }
    /* .descSection::-webkit-scrollbar{
    display:none;
    } */
    /* .descSection2{
      display: flex;
      margin-top: 12px;
      padding-left: 32px;
      padding-right: 32px;
      background-color: red; */

      
      /* direction: rtl;
      text-align: left; */
      /* } */
      .rightBreak{
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .testingDesc{
        font-weight: 300;
        height: 120px;
        padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;
        overflow-y: scroll;
        white-space: normal;
      }
    .rightBarBtn{
    margin-left: 16px;
    margin-right: 16px;
    width: 322px;
    height: 35px;
    background: rgba(0, 0, 0, 1);
    border-radius: 2px;
    color: white;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 21px;
    border:none;
    cursor: pointer;
    }
    .login-error-message{
      color: red;
      text-align: center;
      width: 290px;
      font-size: 14px;
    }
    .adduser-error{
      width: 45.5%;
     
      text-align: center;
      color: red;
    }
    
.emailSection{ display: flex; padding-left: 16px; /* padding-right: 16px; */ margin-bottom: 8px; color: #575757 }
.search-container{
  margin-left: 200px;
  display: flex;
  align-items: center;
  justify-content:center;
}
.project-read-more {
  font-weight: bold;
  text-align: center;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: white;
  cursor: pointer;
  color: #3167a7;
  align-self: center;
  margin-top: auto;
  width: 270px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.leaflet-popup-content {
  width: 100% !important;
  height: 50%;
  padding: 0;
  margin: 0;
}
.sign-btn {
  color: white;
  text-decoration: none;
}
.leaflet-popup-content p{
  margin: 0px !important;
}

.inp {
  display: none;
}

hr.solid {
  /* border: 1px solid #666666;
  width: 354px;
  margin-top: 10px;
  margin-bottom: 10px; */
}
.projName {
  max-height: 90px;
  font-size: 18px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 30ch;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.projNames {
  max-height: 90px;
  text-align: left;
  font-size: 18px;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 30ch;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sdg {
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 16px;
  padding-left: 16px;
}
.sdgs {
  padding-top: 16px;
  text-align: left;
  padding-left: 15px;
}
.since {
  padding-left: 16px;
  padding-left: 16px;
}
.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  width: 92vw;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}

.answer-divider {
  border-top: 1px solid lightgray;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header h1 {
  color: #eee;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.manage-usr {
  padding-bottom: 32px;
  padding-top: 32px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
  padding-left: 16px;
  padding-right: 16px;
}

.inventory-upload {
  padding-bottom: 32px;
  padding-top: 32px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
  padding-left: 115px;
  padding-right: 115px;
}
.mng {
  text-decoration: none;
  color: inherit;
  cursor: auto;
  pointer-events: none;
}

.filter {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 6px;
  border-radius: 2px;
  border: 1px solid #a3a3a3;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.filter img {
  padding-left: 10px;
}
.usr_icon {
  height: 20%;
}
.center {
  text-align: center;
  padding-bottom: 64px;
}
/* Pagination */
.pagination-container {
  list-style-type: none;
}

.pagination-item {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: #4caf50;
}

.arrow::before {
  position: relative;

  content: "";

  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.left {
  transform: rotate(-135deg) translate(-50%);
}

.right {
  transform: rotate(45deg);
}

.disabled {
  pointer-events: none;
}

.arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.arrow :hover {
  background-color: transparent;
  cursor: default;
}

.test {
  float: right;
  margin-right: 780px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}
.init-status {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 400;
  padding-left: 18px;
  padding-top: 10px;
}

.search-container .ongoing {
  width: 50px;
  height: 25px;
  margin-top: 10px;
  margin-left: 5px;
  display: block;
}
.search-container .name-tag {
  float: right;
  padding-top: 15px;
  padding-right: 50px;
  color: #212529;
  font-size: 12px;
  font-weight: 400;
  display: block;
  margin-top: -38px;
}
.search-container .test-tag {
  float: right;
  padding-top: 15px;
  padding-right: 67px;
  color: #212529;
  font-size: 12px;
  font-weight: 400;
  margin-top: -35px;
}
.search-container .final-tag {
  float: right;
  padding-top: 15px;
  padding-right: 55px;
  color: #212529;
  font-size: 12px;
  font-weight: 400;
  margin-top: -45px;
}
.search-container .paused {
  width: 50px;
  height: 25px;
  margin-top: 10px;
  margin-left: 5px;
  display: block;
}
.search-container .completed {
  width: 50px;
  height: 25px;
  margin-top: 10px;
  margin-left: 5px;
  display: block;
  margin-bottom: 10px;
}

.postedapply {
  font-weight: 500;
  font-size: 12px;
  color: #2a68c5;
  float: right;
  margin-right: 30px;
  cursor: pointer;
}
.postedcancel {
  float: left;
  font-weight: 400;
  font-size: 12px;
  color: #989898;
  margin-left: 30px;
  cursor: pointer;
}
.searchtext {
  padding-top: 10px;
  padding-bottom: 30px;
}
.login-home {
  text-decoration: none;
  color: black;
}

.Active {
  background-color: #4caf50;
  padding-left: 16px;
  padding-right: 16px;
}

.Ongoing {
  background-color: #e4bc55;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
.ongoing {
  background-color: #e4bc55;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
#ongoing {
  background-color: #e4bc55;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
.ONGOING {
  background-color: #e4bc55;
  padding-left: 8px;
  padding-right: 8px;
}

/* .topPopup {
  text-align: center;
  font-size: 12px;
  color: white;
  width: auto;
  height: 50px;
  font-weight: 800;
  border-radius: 5px 5px 0px 0px;
  padding-top: 15px;
}
.topPopUp {
  text-align: center;
  font-size: 12px;
  color: white;
  width: auto;
  height: 50px;
  font-weight: 800;
  padding-top: 15px;
} */
#ONGOING {
  background-color: #e4bc55;
}
/* #COMPLETE {
  background-color: #4caf50;
}
#Complete {
  background-color: #4caf50;
  text-transform: uppercase;
} */
#Ongoing {
  background-color: #e4bc55;
  text-transform: uppercase;
}
.INACTIVE {
  background-color: #e4bc55;
  padding-left: 16px;
  padding-right: 16px;
}
.COMPLETE {
  background-color: #4caf50;
  padding-left: 16px;
  padding-right: 16px;
}
.Complete {
  background-color: #4caf50;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
.complete {
  background-color: #4caf50;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
.ACTIVE {
  background-color: #4caf50;
  padding-left: 16px;
  padding-right: 16px;
}

.table-test {
  display: none;
}
.lock {
  cursor: pointer;
  background-color: transparent;
}
.row-img {
  width: auto;
  height: 25px;
}
.row-img1 {
  width: auto;
  height: 10px;
  padding-left: 10px;
}
.return {
  background-color: transparent;
  cursor: pointer;
}
.rightBarBtn a:visited {
  color: white;
}
.rightBarBtn a {
  text-decoration: none;
  color: white;
}
.lock1 {
  cursor: pointer;
  background-color: transparent;
}
.lock2 {
  height: 20px;
  width: 20px;
  cursor: pointer;
  background-color: transparent;
}
.lock3 {
  height: 20px;
  width: 20px;
  cursor: pointer;
  background-color: transparent;
  margin-left: 20px;
}
.lock-open {
  cursor: pointer;
  background-color: #ffffff;
}
.filter-bar {
  padding: 32px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 388px;
  color: white;
  background-color: #ba2f29;
}
.filter-bar p {
  font-size: 12px;
  margin-bottom: 32px;
}
.filter-bar h1 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 18px;
}
.filter-dropdown1 {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.filter-dropdown1 label {
  font-size: 14px;
}
.filter-dropdown1 select {
  margin-top: 8px;
  height: 35px;
  width: 324px;
}
.Filter-btn {
  height: 35px;
  width: 324px;
  margin-bottom: 32px;
  margin-top: 32px;
  font-size: 12px;
  font-weight: bold;
  background-color: black;
  color: white;
  border: none;
}
.Reset-btn {
  height: 35px;
  width: 324px;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.search-container {
  margin-left: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-container-manage {
  margin-left: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.test {
  margin-left: 200px;
}
.popupTest {
  max-height: 100%;
  margin-top: 1px;
}
.popupPage {
  max-height: 380px;
  width: 360px;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-y: auto;
}
.popupPage::-webkit-scrollbar {
  display: none;
}
.popupPage.no-image {
  overflow-y: hidden;
  background-color: red;
}
.right-arrow {
  margin-top: 40px;
  font-size: 12px;
  font-weight: 600;
  float: right;
  margin-right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
}
.rowOptions {
  display: inline-flex;
  align-items: center;
}
.left-arrow {
  margin-top: 40px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Kumbh Sans", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
}

#helper {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 165%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

#helper1 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 165%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

#helper2 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 145%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper3 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 145%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper4 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 165%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper5 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 145%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper6 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 136%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper7 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 136%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper8 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 106.5%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper9 {
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
  height: 202.5%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#helper10 {
  width: 100%;
  height: 151.5%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
#popUpBox {
  width: 500px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 1px black;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 10px;
  text-align: center;
  padding: 32px;
}
#popUpBox1 {
  width: 500px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 1px black;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 10px;
  text-align: center;
  padding: 32px;
}
.alert_button {
  padding: 8px 16px 8px 16px;
  background-color: rgba(135, 30, 26, 1);
  display: block;
  width: 100%;
  border-style: none;
  color: white;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
}
.alert_button1 {
  padding: 8px 16px 8px 16px;
  background-color: rgba(135, 30, 26, 1);
  border-style: none;
  color: white;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  display: block;
  float: left;
  margin-left: 25%;
}
.alert_button2 {
  float: right;
  padding: 8px 16px 8px 16px;
  background-color: rgba(135, 30, 26, 1);
  margin-top: 10px;
  border-style: none;
  color: white;
  font-size: 12px;

  cursor: pointer;
  display: block;
  margin-right: 25%;
}

.alertContainer {
  font-size: 12px;
  font-weight: 600;

  background-color: white;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.06);
}
.popUpText {
  font-weight: 600;
}
.pageNum {
  position: relative;
  top: 100px;
  left: 120px;
}
#file-label {
  border: 1px solid black;
  padding: 8px 8px 8px 8px;
  color: black;
  margin-right: 4%;
  cursor: pointer;
}
.clearFilt {
  border-style: none;
  background-color: none;
  background: none;
  color: white;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.homePage {
  position: relative;
}
.side-bar {
  position: absolute;
  background-color: #34393e;
  z-index: 100;
  width: 30%;
  right: 0;
  box-shadow: 5px 10px 18px #888888;
  display: none;
  top: 48px;
}
.side-bar img {
  height: 100%;
}
.side-bar-logo {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}
.dash-item {
  width: 100%;
  height: 8%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  margin-bottom: 2.5%;
  border-left: none;
  border-right: none;
  user-select: none;
  font-weight: 530;
}
.dash-item:hover {
  background-color: rgba(57, 179, 176, 0.2);
}
.nav-btn {
  position: absolute;
  right: 5%;
  top: 30%;
  border: none;
  background-color: transparent;
  display: none;
}
.filterButtonResp {
  display: none;
}
.responseFilter {
  display: none;
}
.mobileAdd {
  display: none;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 10px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.grid-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  width: 300px;
}
.topInitInven {
  display: inline-block;
  padding-bottom: 32px;
  padding-left: 115px;
  padding-top: 64px;
}
.projectImageUrls {
  width: 100%;
  height: 200px; /* Adjust this value to your desired height */
  object-fit: cover;
  padding: 0;
}

.locatedInit {
  text-align: left;
  padding-left: 15px;
  padding-top: 16px;
}
@media screen and (max-width: 600px) {
  .homePage .logo1,
  .logo2,
  .navigation-bar-right {
    display: none;
  }
  .navigation-bar-default {
    padding-top: 30px;
    background-color: #871e1a;
    display: block;
    margin: 0;
  }
  body {
    overflow-x: hidden;
    margin: 0;
    height: 100%;
  }
  .mobileName {
    color: white;
    right: 290px;
    position: absolute;
    font-size: 24px;
  }
  .side-bar p {
    color: white;
  }
  .nav-btn {
    display: block;
  }
  .pop-out-bar {
    display: none;
  }
  .SDGs-section-content {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .mapping-ini {
    padding: 32px;
    background-color: white;
  }

  .dark-button-large {
    display: none;
  }
  .faq h1 {
    color: #ba2f29;
  }
  .faq {
    padding: 32px;
  }
  .footer p,
  li {
    display: none;
  }
  .sec-bar-2 {
    display: none;
  }
  .mapping-ini a {
    display: none;
  }
  .responseFilter {
    display: flex;
    background-color: white;
    overflow-x: scroll;
    width: 100%;
    height: 43px;
    overflow-y: hidden;
  }
  .filterButtonResp {
    display: block;
  }
  .filterButtonResp button {
    width: 69px;
    height: 27px;
    margin: 8px 4px 8px 8px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 3px;
  }
  .filterName h1 {
    margin: 8px 4px 8px 8px;
    width: 57px;
    height: 19px;
    font-size: 12px;
    padding-top: 5px;
  }
  .respImg {
    width: 12px;
    height: 12px;
    padding-right: 0px;
    margin-right: 5px;
  }
  .respImg2 {
    width: 12px;
    height: 12px;
    padding-right: 0px;
    margin-right: 5px;
  }
  .respImgFilter {
    width: 12px;
    height: 12px;
    padding-right: 0px;
    margin-right: 5px;
  }
  .overlay-img1 {
    display: none;
  }
  .overlay-img2 {
    display: none;
  }
  .image-container {
    display: block;
  }
  .image-container p {
    font-size: 12px;
  }
  .img-1,
  .img-2 {
    border: 1px solid #e0e0e0;
  }
  .img-1 {
    margin-bottom: 8px;
    margin-right: 0px;
  }
  .box h1 {
    margin-bottom: 16px;
  }
  .showAdm {
    display: block;
  }
  .up-init,
  .up-drag-img {
    background: white;
    border: white;
    height: 330px;
  }
  #file-label {
    display: none;
  }
  .init {
    margin-top: 32px;
    margin-left: 32px;
    text-align: left;
    padding-top: 0px;
  }
  .p-init {
    text-align: left;
    margin-left: 32px;
    padding-bottom: 0px;
    margin-top: 16px;
    padding-top: 0px;
    margin-bottom: 32px;
    color: #6b6b6b;
  }
  .p-init br {
    display: none;
  }
  .up-drag-img img {
    display: none;
  }
  #files {
    display: none;
  }
  .mobileAdd {
    display: flex;
    position: relative;
    bottom: 550px;
    margin-left: 32px;
  }
  .mobileUpload {
    background-color: #871e1a;
    border-radius: 2px;
    border-style: none;
    color: white;
    width: 89px;
    height: 36px;
    font-weight: bold;
    font-size: 12px;
    margin-top: 32px;
    cursor: pointer;
  }
  .mobileButton {
    font-size: 12px;
    width: 89px;
    height: 36px;
    padding-left: 10px;
    cursor: pointer;
    border: none;
    margin-top: 32px;
    background-color: white;
  }
}
